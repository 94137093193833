import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import Circle from "../Circle";


const MyComponent = ({data}) => {



    const  corporate =   data?.posts?.list?.find(e => e?.data?.slug==="corporate");
    const  head =   data?.posts?.list?.find(e => e?.data?.slug==="head-office");
    const  factory =   data?.posts?.list?.find(e => e?.data?.slug==="factory");
    const  phone =   data?.posts?.list?.find(e => e?.data?.slug==="phone");
    const arrayOfNumbers = phone?.data?.description ? phone?.data?.description?.split(',') : '';
    const  email =   data?.posts?.list?.find(e => e?.data?.slug==="email");
    const arrayOfEmails = email?.data?.description ? email?.data?.description?.split(',') : '';

    console.log('data', data)
    return (
        <StyledComponent >
            <Container className={'pt-150 pb-150'}>
                <Circle/>
                <h2>{reactHtmlParser(data?.section_data?.subtitle)}</h2>
                <div className={'info'}>

                    <Col md={4} className={'info__single address'}>
                        <h4>{reactHtmlParser(corporate?.data?.subtitle)}</h4>
                        <p>
                            <a href={corporate?.data?.short_desc} target={"_blank"}>
                                {reactHtmlParser(corporate?.data?.description)}
                            </a>
                        </p>
                    </Col>
                    <Col md={4} className={'info__single address'}>
                        <h4>{reactHtmlParser(head?.data?.subtitle)}</h4>
                        <p>
                            <a href={head?.data?.short_desc} target={"_blank"}>
                                {reactHtmlParser(head?.data?.description)}
                            </a>
                        </p>
                    </Col>

                    <Col md={4} className={'info__single address'}>
                        <h4>{reactHtmlParser(factory?.data?.subtitle)}</h4>
                        <p>
                            <a href={factory?.data?.short_desc} target={"_blank"}>
                                {reactHtmlParser(factory?.data?.description)}
                            </a>
                        </p>
                    </Col>

                    <Col md={4} className={'info__single phone'}>
                        <h4>{reactHtmlParser(phone?.data?.subtitle)}</h4>
                        {
                            arrayOfNumbers && arrayOfNumbers?.length > 0 &&
                            arrayOfNumbers?.map((number, index) => (
                                <p key={index}>
                                    <a href={`tel:${number}`} target={"_blank"}>
                                        {reactHtmlParser(number)}
                                    </a>
                                </p>
                            ))
                        }
                    </Col>

                    <Col md={4} className={'info__single email'}>
                        <h4>{reactHtmlParser(email?.data?.subtitle)}</h4>
                        {
                            arrayOfEmails && arrayOfEmails?.length > 0 &&
                            arrayOfEmails?.map((number, index) => (
                                <p key={index}>
                                    <a href={`mailto:${number}`} target={"_blank"}>
                                        {reactHtmlParser(number)}
                                    </a>
                                </p>
                            ))
                        }
                    </Col>
                </div>
            </Container>
            <div className={'map'}>
                <a href={data?.section_data?.short_desc}>
                    <Img src={data?.images?.list[0]?.full_path}/>
                </a>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  h2{
    color: #000000;
  }
    .info{
      display: flex;
        flex-wrap: wrap;
      &__single{
          margin-top: 40px;
        //max-width: 285px;
        //margin-right: 60px;
        &:last-child{
          margin-right: 0;
        }
        h4{
          margin-bottom: 20px;
        }

        
        &.phone{
          p{
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-top: 0 !important;
          }
        }
      }
      @media(max-width: 767px){
        display: block;
       &__single{
         margin-right: 0 !important;
         margin-bottom: 40px;
         p{
           margin-top: 10px;
         }
         &:last-child{
           margin-bottom: 0;
         }
       }
      }
    }
  .map{
    position: relative;
    padding-top: calc(600 / 1366 * 100%)
  }
`;

export default MyComponent;
